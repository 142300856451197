@mixin normal-transition( $properties ){
	transition: #{ $properties } 150ms ease-in-out;
}

@mixin animation-rotate( $animationName, $animationDuration, $startPoint, $endPoint, $translateX, $translateY ){
	animation: #{ $animationName } #{ $animationDuration } linear infinite;

	@keyframes #{ $animationName } {
		from {
			transform: translate( #{ $translateX }, #{ $translateY } ) rotate( #{ $startPoint } );
		}
		to {
			transform: translate( #{ $translateX }, #{ $translateY } ) rotate( #{ $endPoint } );
		}
	}
}

@mixin placeholder-shimmer {
	animation: placeHolderShimmer 1s steps(10) 1s infinite alternate;
}
