.ultp-course-timer-modal {
	.dialog-btn span {
		height: auto;

		padding: 5px 10px;
		line-height: 1.4;
	}

	.dialog-btn {
		display: flex;
		align-items: flex-start;
		justify-content: center;
	}
}