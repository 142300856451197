@import './variables.scss';

$icon-font-url: '@common/fonts/uncanny-toolkit-pro-icons';

@font-face {
	font-family: 'Uncanny Toolkit Pro Icons';
	src: url( $icon-font-url + '.eot' );
	src:
		url( $icon-font-url + '.eot' ) format( 'embedded-opentype' ),
		url( $icon-font-url + '.ttf' ) format( 'truetype' ),
		url( $icon-font-url + '.woff' ) format( 'woff' ),
		url( $icon-font-url + '.svg' ) format( 'svg' );
	font-weight: normal;
	font-style: normal;
}

@mixin icons-font {
	font-family: 'Uncanny Toolkit Pro Icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ultp-icon {
	@include icons-font;

	&--angle-down:before {
		content: $icon-angle-down;
	}
	&--angle-left:before {
		content: $icon-angle-left;
	}
	&--angle-right:before {
		content: $icon-angle-right;
	}
	&--angle-up:before {
		content: $icon-angle-up;
	}
	&--check:before {
		content: $icon-check;
	}
	&--lock-alt:before {
		content: $icon-lock-alt;
	}
	&--times:before {
		content: $icon-times;
	}
	&--spinner-third:before {
		content: $icon-spinner-third;
	}

	&--plus-circle:before {
		content: $icon-plus-circle;
	}

	&--minus-circle:before {
		content: $icon-minus-circle;
	}

	&--times-circle:before {
		content: $icon-times-circle;
	}

	&--check-circle:before {
		content: $icon-check-circle;
	}

	&--question-circle:before {
		content: $icon-question-circle;
	}

	&--info-circle:before {
		content: $icon-info-circle;
	}

	&--exclamation-circle:before {
		content: $icon-exclamation-circle;
	}
}