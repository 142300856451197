.ultp-download-certificates {
	width: 100%;
	max-width: 500px !important;

	&-filter {
		$filter: &;

		margin-bottom: 20px;

		select {
			width: 100%;
		}

		label {
			font-weight: 600;
			margin-bottom: 8px;
			
			display: inline-block;
			position: relative;
		}

		&--loading label:after {
			content: '';
		
			width: 16px;
			height: 16px;

			opacity: .5;
		
			background-image: url("data:image/svg+xml,%3Csvg xmlns:svg='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.0' width='64px' height='64px' viewBox='0 0 128 128' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z' fill='%23000' fill-opacity='1'/%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 64 64' to='360 64 64' dur='1800ms' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/g%3E%3C/svg%3E");
			background-position: center;
			background-size: contain;
		
			position: absolute;
			top: 50%;
			right: calc(-16px - 10px);
			transform: translateY(-50%);
		}

		&--hidden {
			display: none !important;
		}
	}

	&-filters {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	&-error {
		color: #000;
		background: #f9e2e2;

		padding: 15px;

		border-radius: 10px;
	}
}

.ultp-download-certificates-dropdowns-error {
	color: red;

	font-size: .9em;
	margin-top: 5px;
	padding-bottom: 15px;
}

.ultp-download-certificates-dropdowns-error:empty {
	display: none;
}

#uo_download_certificates {
	.select2-selection.select2-selection--single {
		background-image: none !important;
	}

	.hide_block{
		display: none;
	}
	.uo-generate-certificates-btn{
		margin-top: 25px;
	}
	
	.uo-certificates-success,
	.uo-certificates-error {
		display: flex;
		gap: 10px;
		color: #000;
		border-radius: 10px;
		padding: 20px;
		margin: 25px 0;
	}
		.uo-certificates-success::before {
			content: '';
			flex: 0 0 auto;
			background-repeat: no-repeat;
			background-size: contain;
			width: 18px;
			height: 18px;
			margin-top: 3px;
		}

	.uo-certificates-error {
		background-color: var(--uap-download-certificates-notice-error-background, #f9e2e2);
	}
	
	.uo-certificates-success {
		background-color: var(--uap-download-certificates-notice-success-background, #ecf7ed);
		
		&::before {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23000' d='M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z'/%3E%3C/svg%3E");
		}
	}


	#uo_generate_certificates_sbmt{
		border-radius: 25px;
	}
	.uo_clicked_btn{
		opacity: 0.5;
	}
	.block_opacity{
		opacity: 0.3;
		pointer-events:none;
		cursor: progress;
	}

	.ultp-download-certificates-download {
		display: flex;
		align-items: flex-start;
		
    	gap: 8px;
    	flex-direction: column;

		&-text {
			font-size: 16px;
			font-weight: 500;
		}

		&-button {
			text-decoration: none;

			display: inline-flex;
			align-items: center;
			gap: 12px;

			font-size: 16px;
			font-weight: 600;

			background-color: var(--uap-download-certificates-button-background, #000);
			color: var(--uap-download-certificates-button-font-color, #fff);

			border-radius: 10px;

			padding: 8px 20px;

			@supports (mask-size: contain) or (-webkit-mask-size: contain) {
				$download_icon: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath d='m18,12.94v2.81c0,1.24-1.01,2.25-2.25,2.25H2.25c-1.24,0-2.25-1.01-2.25-2.25v-2.81c0-1.24,1.01-2.25,2.25-2.25h1.88l1.69,1.69h-3.57c-.31,0-.56.25-.56.56v2.81c0,.31.25.56.56.56h13.5c.31,0,.56-.25.56-.56v-2.81c0-.31-.25-.56-.56-.56h-3.57l1.69-1.69h1.88c1.24,0,2.25,1.01,2.25,2.25ZM3.59,8.19c-.33-.33-.33-.86,0-1.19.33-.33.86-.33,1.19,0l3.37,3.34V.84c0-.47.38-.84.84-.84s.84.38.84.84v9.5l3.34-3.34c.33-.33.86-.33,1.19,0,.33.33.33.86,0,1.19l-4.78,4.78c-.17.17-.38.25-.6.25s-.43-.08-.6-.28l-4.81-4.75Zm10.75,7c-.47,0-.84-.38-.84-.84s.38-.84.84-.84.84.38.84.84-.38.84-.84.84Z'/%3E%3C/svg%3E");

				&::before {
					content: '';
					flex: 0 0 auto;
					display: block;

					width: 18px;
					height: 18px;

					background: var(--uap-download-certificates-button-font-color, #fff);

					mask-position: center;
					mask-size: contain;
					mask-image: $download_icon;
					mask-repeat: no-repeat;

					-webkit-mask-position: center;
					-webkit-mask-size: contain;
					-webkit-mask-image: $download_icon;
					-webkit-mask-repeat: no-repeat;
				}
			}

			&:hover {
				text-decoration: none;
			}
		}
	}

	.ultp-download-certificates-clear {
		padding-top: 20px;
	}
}
