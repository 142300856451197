/**
 * Fonts
 */

$font_family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

/**
 * Dimensions
 */

/* Course Dashboard */
$dashboard-global-font-size: 15px;

$dashboard-space-between-actions-and-box: 20px;

$dashboard-rows-vertical-padding: 11px;
$dashboard-space-between-elements: 15px;

$dashboard-toggle-button-width: 45px;
$dashboard-toggle-button-width-mobile: 40px;
$dashboard-toggle-button-icon-size: 15px;

$dashboard-course-name-font-size: 15px;
$dashboard-course-ceus-numbers-font-size: 13px;
$dashboard-course-ceus-text-font-size: 12px;

$dashboard-button-font-size: 14px;
$dashboard-button-padding: 5px 10px;

$dashboard-progress-bar-font-size: 14px;
$dashboard-progress-bar-height: 4px;

$dashboard-status-circle-width: 23px;
$dashboard-status-circle-font-size: 14px;

$dashboard-border-radius: 3px;

$dashboard-lesson-available-on-font-size: 12px;

$dashboard-quiz-date-font-size: 14px;
$dashboard-quiz-score-font-size: 14px;
$dashboard-quiz-score-padding: 5px 10px;

$dashboard-section-header-font-size: 15px;

/* Lazy Course Content */
$lazy-course-navigation-global-font-size: 15px;

$lazy-course-navigation-loading-icon-size: 25px;

$lazy-course-navigation-heading-font-size: 25px;

$lazy-course-navigation-space-between-loading-elements: 15px;
$lazy-course-navigation-space-between-heading-and-content: 20px;

/**
 * Breakpoints
 */

$dashboard-big-breakpoint: 700px;
$dashboard-medium-breakpoint: 600px;
$dashboard-small-breakpoint: 500px;

/**
 * Colors
 */

/* Course Dashboard */
$color-dashboard-font-color: #1e1e1e;

$color-dashboard-border: #d9d9d9;
$color-dashboard-inner-border: #d9d9d9;

$color-url-hover: #015d9e;

$color-dashboard-toggle-font-color: #686868;
$color-dashboard-toggle-bg: transparent;
$color-dashboard-toggle-expanded-bg: #019e7c;
$color-dashboard-toggle-expanded-font-color: #fff;
$color-dashboard-toggle-disabled: #f3f3f3;

$color-dashboard-button-color: #1e1e1e;
$color-dashboard-button-border: #c0c0c0;
$color-dashboard-button-border-hover: #1e1e1e;

$color-dashboard-ceus-color: #6d6d6d;

$color-dashboard-progress-bar-font-color: #1e1e1e;
$color-dashboard-progress-bar-holder-background: #d9d9d9;

$color-dashboard-progress: #019e7c;

$color-dashboard-lesson-available-on-font-color: #8f8f8f;

$color-dashboard-lesson-content: #f9f9f9;

$color-dashboard-status-circle-border: #c9c9c9;
$color-dashboard-status-circle-font-color: #b7b7b7;
$color-dashboard-status-circle-completed-bg: #019e7c;
$color-dashboard-status-circle-completed-font-color: #fff;

$color-dashboard-quiz-toggle-expanded-bg: #d9d9d9;
$color-dashboard-quiz-toggle-expanded-font-color: #686868;
$color-dashboard-quiz-score-passed-font-color: #fff;
$color-dashboard-quiz-score-passed-bg: #019e7c;
$color-dashboard-quiz-score-failed-font-color: #fff;
$color-dashboard-quiz-score-failed-bg: #e88a10;
$color-dashboard-quiz-date: #8f8f8f;

$color-section-header-background: #ebebeb;
$color-section-header-font-color: #1e1e1e;

/* Lazy Course Content */
$color-lazy-course-navigation-font-color: #1e1e1e;

/**
 * Icons
 */

$icon-angle-down:         '\e900';
$icon-angle-left:         '\e901';
$icon-angle-right:        '\e902';
$icon-angle-up:           '\e903';
$icon-check:              '\e904';
$icon-lock-alt:           '\e905';
$icon-times:              '\e906';
$icon-spinner-third:      '\e907';

$icon-plus-circle:        '\e908';
$icon-minus-circle:       '\e909';
$icon-times-circle:       '\e910';
$icon-check-circle:       '\e911';
$icon-question-circle:    '\e912';
$icon-info-circle:        '\e913';
$icon-exclamation-circle: '\e914';