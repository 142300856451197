.ultp-dashboard {
	$class: '.ultp-dashboard';

	font-family: $font_family;
	font-size: $dashboard-global-font-size;
	color: $color-dashboard-font-color;

	line-height: 1.4;

	box-sizing: border-box;

	* {
		box-sizing: border-box;
	}

	&-box {
		border: 1px solid $color-dashboard-border;
		border-radius: $dashboard-border-radius;
	}

	&-btn {
		font-size: $dashboard-button-font-size;
		color: $color-dashboard-button-color;

		border: 1px solid $color-dashboard-button-border;
		border-radius: $dashboard-border-radius;
		padding: $dashboard-button-padding;

		line-height: 1;
		text-decoration: none;
		cursor: pointer;

		display: inline-block;

		@include normal-transition( 'border-color' );

		&:hover {
			border-color: $color-dashboard-button-border-hover;
		}

		& + & {
			margin-left: 7px;
		}
	}

	&-toolbar {
		width: 100%;

		display: flex;
		justify-content: flex-end;

		padding-bottom: $dashboard-space-between-actions-and-box;

		#{ $class }-filters {
			flex: 1 1 auto;

			min-width: 0px;

			padding-right: $dashboard-space-between-actions-and-box;

			&-form {
				display: flex;

				#{ $class }-filter {
					&__label {
						font-weight: 400;
						margin-bottom: 6px;
					}

					select {
						font-family: inherit;
						font-size: inherit;
						font-style: inherit;

						max-width: 100%;
					}

					&:not(:last-child) {
						margin-right: calc( $dashboard-space-between-actions-and-box / 2 );
					}
				}
			}	
		}

		#{ $class }-actions {
			flex: 0 0 auto;

			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			#{ $class }-btn {
				margin-left: 0;

				&:not(:last-child) {
					margin-right: calc( $dashboard-space-between-actions-and-box / 2 );
				}
			}
		}
	}

	&-course {
		$section_class: &;

		&__row {
			display: flex;

			border-top: 1px solid $color-dashboard-inner-border;

			#{ $section_class }__toggle-btn {
				border-right: 1px solid $color-dashboard-inner-border;
				width: $dashboard-toggle-button-width;

				font-size: $dashboard-course-name-font-size;

				color: $color-dashboard-toggle-font-color;

				flex: 0 0 auto;

				position: relative;

				@include normal-transition( 'background, color' );

				background: $color-dashboard-toggle-disabled;

				#{ $section_class }--has-lessons &,
				#{ $section_class }--has-quizzes & {
					background: $color-dashboard-toggle-bg;
					cursor: pointer;
				}

				#{ $section_class }--has-lessons &:before,
				#{ $section_class }--has-quizzes &:before {
					@include icons-font;
					content: $icon-angle-down;
					font-size: $dashboard-toggle-button-icon-size;

					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
				}

				#{ $section_class }--expanded &,
				#{ $section_class }--expanding & {
					background: $color-dashboard-toggle-expanded-bg;
					border-right: $color-dashboard-toggle-expanded-bg;

					&:before {
						content: $icon-angle-up;
						color: $color-dashboard-toggle-expanded-font-color;
					}
				}

				/* Mobile */
				#{ $class }[max-width~="#{ $dashboard-small-breakpoint }"] & {
					width: $dashboard-toggle-button-width-mobile;
				}
			}

			#{ $section_class }__details {
				flex: 1 1 auto;
				display: flex;
				min-width: 0;

				align-items: center;

				#{ $section_class }__left {
					width: 100%;
					flex: 1 1 auto;
					min-width: 0;

					padding-left: $dashboard-space-between-elements;
					padding-right: $dashboard-space-between-elements;

					#{ $section_class }__name {
						width: 100%;
						display: block;

						position: relative;

						padding-top: $dashboard-rows-vertical-padding;
						padding-bottom: $dashboard-rows-vertical-padding;

						a {
							width: 100%;
							display: block;

							position: relative;

							/*
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							*/
							
							color: inherit;
							text-decoration: none;

							outline: none;

							@include normal-transition( 'color' );

							&:hover {
								color: $color-url-hover;
							}

							/* Tablet and mobile */
							#{ $class }[max-width~="#{ $dashboard-medium-breakpoint }"] & {
								white-space: normal;
								overflow: visible;
								text-overflow: clip;
							}
						}
					}
				}

				#{ $section_class }__right {
					flex: 0 0 auto;
					display: flex;

					padding-right: $dashboard-space-between-elements;

					#{ $section_class }__ceus {
						padding-right: $dashboard-space-between-elements;

						line-height: 1.2;
						text-align: right;

						color: $color-dashboard-ceus-color;

						&-numbers {
							font-size: $dashboard-course-ceus-numbers-font-size;
						}

						&-text {
							font-size: $dashboard-course-ceus-text-font-size;
						}

						#{ $class }[max-width~="#{ $dashboard-small-breakpoint }"] & {
							width: 100%;
							text-align: left;
							padding-bottom: $dashboard-rows-vertical-padding;
						}
					}

					#{ $section_class }__action {
						padding-right: $dashboard-space-between-elements;
						padding-top: 1px;
					}

					#{ $section_class }__progress {
						display: flex;
						flex-direction: column;

						font-size: $dashboard-progress-bar-font-size;
						color: $color-dashboard-progress-bar-font-color;

						&-sizer {
							height: 0px;
							pointer-events: none;
							visibility: hidden;
							position: relative;
							z-index: -1;
						}

						&-percentage {
							margin-bottom: 5px;
						}

						&-holder {
							width: 100%;
							height: $dashboard-progress-bar-height;
							background-color: $color-dashboard-progress-bar-holder-background;
							border-radius: 150px;

							position: relative;
							overflow: hidden;
						}

						&-bar {
							height: 100%;
							background-color: $color-dashboard-progress;

							position: absolute;
							top: 0px;
							left: 0px;
						}
					}

					/* Mobile */
					#{ $class }[max-width~="#{ $dashboard-small-breakpoint }"] & {
						width: 100%;
						padding-left: $dashboard-space-between-elements;
						padding-right: $dashboard-space-between-elements;
						padding-bottom: $dashboard-rows-vertical-padding;

						flex-wrap: wrap;
					}
				}

				/* Mobile */
				#{ $class }[max-width~="#{ $dashboard-small-breakpoint }"] & {
					flex-wrap: wrap;
				}
			}
		}

		&:first-child &__row {
			border-top: 0;
		}

		&__content {
			display: none;
			padding-left: $dashboard-toggle-button-width - 1px;

			#{ $section_class }--expanded & {
				display: block;
			}

			/* Mobile */
			#{ $class }[max-width~="#{ $dashboard-small-breakpoint }"] & {
				padding-left: $dashboard-toggle-button-width-mobile - 1px;
			}
		}

		&__lessons,
		&__quizzes {
			border-left: 1px solid $color-dashboard-border;
		}
	}

	&-section {
		$section_class: &;

		&__header {
			display: flex;

			background: $color-section-header-background;
			color: $color-section-header-font-color;

			border-top: 1px solid $color-dashboard-inner-border;

			&-toggle-btn {
				border-right: 1px solid $color-dashboard-inner-border;
				width: $dashboard-toggle-button-width;

				font-size: $dashboard-course-name-font-size;
				cursor: pointer;

				flex: 0 0 auto;

				position: relative;

				@include normal-transition( 'background, color' );

				&:before {
					@include icons-font;
					content: $icon-angle-down;
					font-size: $dashboard-toggle-button-icon-size;

					color: $color-dashboard-toggle-font-color;

					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
				}

				#{ $section_class }--expanded &,
				#{ $section_class }--expanding & {
					background: $color-dashboard-quiz-toggle-expanded-bg;

					&:before {
						content: $icon-angle-up;
						color: $color-dashboard-quiz-toggle-expanded-font-color;
					}
				}

				/* Mobile */
				#{ $class }[max-width~="#{ $dashboard-small-breakpoint }"] & {
					width: $dashboard-toggle-button-width-mobile;
				}
			}

			&-title {
				flex: 1 1 auto;

				padding-left: $dashboard-space-between-elements;
				padding-right: $dashboard-space-between-elements;
				padding-top: $dashboard-rows-vertical-padding;
				padding-bottom: $dashboard-rows-vertical-padding;

				font-size: $dashboard-section-header-font-size;
			}
		}

		&__content {
			display: none;

			#{ $section_class }--expanded &,
			#{ $section_class }--expanding & {
				display: block;
			}
		}
	}

	&-lesson {
		$section_class: &;

		&__row {
			display: flex;

			border-top: 1px solid $color-dashboard-inner-border;

			#{ $section_class }__toggle-btn {
				border-right: 1px solid $color-dashboard-inner-border;
				width: $dashboard-toggle-button-width;

				font-size: $dashboard-course-name-font-size;
				
				flex: 0 0 auto;

				position: relative;

				@include normal-transition( 'background, color' );

				background: $color-dashboard-toggle-disabled;

				#{ $section_class }--has-topics &,
				#{ $section_class }--has-quizzes & {
					background: $color-dashboard-toggle-bg;
					cursor: pointer;
				}

				#{ $section_class }--has-topics &:before,
				#{ $section_class }--has-quizzes &:before {
					@include icons-font;
					content: $icon-angle-down;
					font-size: $dashboard-toggle-button-icon-size;

					color: $color-dashboard-toggle-font-color;

					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
				}

				#{ $section_class }--expanded &,
				#{ $section_class }--expanding & {
					background: $color-dashboard-toggle-expanded-bg;
					border-right: $color-dashboard-toggle-expanded-bg;

					&:before {
						content: $icon-angle-up;
						color: $color-dashboard-toggle-expanded-font-color;
					}
				}

				/* Mobile */
				#{ $class }[max-width~="#{ $dashboard-small-breakpoint }"] & {
					width: $dashboard-toggle-button-width-mobile;
				}
			}

			#{ $section_class }__details {
				flex: 1 1 auto;
				display: flex;
				min-width: 0;

				align-items: center;

				#{ $section_class }__available-on {
					font-size: $dashboard-lesson-available-on-font-size;
					padding-right: $dashboard-space-between-elements;
					color: $color-dashboard-lesson-available-on-font-color;
					text-align: right;

					#{ $section_class }__available-on-text {}

					#{ $section_class }__available-on-date {}

					/* Tablet and mobile */
					#{ $class }[max-width~="#{ $dashboard-medium-breakpoint }"] & {}
				}

				#{ $section_class }__left {
					width: 100%;
					flex: 1 1 auto;
					min-width: 0;

					padding-left: $dashboard-space-between-elements;
					padding-right: $dashboard-space-between-elements;

					#{ $section_class }__name {
						width: 100%;
						display: block;

						position: relative;

						padding-top: $dashboard-rows-vertical-padding;
						padding-bottom: $dashboard-rows-vertical-padding;

						a {
							width: 100%;
							display: block;

							position: relative;

							/*
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							*/
							
							color: inherit;
							text-decoration: none;

							outline: none;

							@include normal-transition( 'color' );

							&:hover {
								color: $color-url-hover;
							}

							/* Tablet and mobile */
							#{ $class }[max-width~="#{ $dashboard-medium-breakpoint }"] & {
								white-space: normal;
								overflow: visible;
								text-overflow: clip;
							}
						}
					}

					#{ $section_class }__available-on {
						display: none;
						margin-top: 3px;

						#{ $section_class }__available-on-text,
						#{ $section_class }__available-on-date {
							display: inline;
						}

						/* Tablet and mobile */
						#{ $class }[max-width~="#{ $dashboard-medium-breakpoint }"] & {
							display: block;
						}
					}
				}

				#{ $section_class }__right {
					flex: 0 0 auto;
					display: flex;
					align-items: center;

					padding-right: $dashboard-space-between-elements;

					#{ $section_class }__status {

						#{ $section_class }__status-circle {
							width: $dashboard-status-circle-width;
							height: $dashboard-status-circle-width;

							position: relative;

							border-radius: 50%;
							border: 1px solid $color-dashboard-status-circle-border;

							&:before {
								@include icons-font;
								content: $icon-check;
								font-size: $dashboard-status-circle-font-size;

								color: $color-dashboard-status-circle-font-color;

								position: absolute;
								top: calc( 50% + 1px );
								left: 50%;
								transform: translate(-50%,-50%);
							}

							#{ $section_class }--completed & {
								background: $color-dashboard-status-circle-completed-bg;
								border-color: $color-dashboard-status-circle-completed-bg;

								&:before {
									color: $color-dashboard-status-circle-completed-font-color;
								}
							}
						}
					}

					#{ $section_class }__available-on {
						/* Tablet and mobile */
						#{ $class }[max-width~="#{ $dashboard-medium-breakpoint }"] & {
							display: none;
						}
					}

					/* Tablet and mobile */
					#{ $class }[max-width~="#{ $dashboard-medium-breakpoint }"] #{ $section_class }--not-available & {
						padding-top: $dashboard-rows-vertical-padding;
					}
				}

				/* Tablet and mobile */
				#{ $class }[max-width~="#{ $dashboard-medium-breakpoint }"] #{ $section_class }--not-available & {
					align-items: flex-start;
				}
			}
		}

		&__content {
			display: none;
			padding-left: $dashboard-toggle-button-width - 1px;

			#{ $section_class }--expanded & {
				display: block;
			}

			/* Mobile */
			#{ $class }[max-width~="#{ $dashboard-small-breakpoint }"] & {
				padding-left: $dashboard-toggle-button-width-mobile - 1px;
			}
		}

		&__topics,
		&__quizzes {
			border-left: 1px solid $color-dashboard-border;
		}
	}

	&-topic {
		$section_class: &;

		&__row {
			display: flex;

			border-top: 1px solid $color-dashboard-inner-border;
			background: $color-dashboard-lesson-content;

			#{ $section_class }__toggle-btn {
				border-right: 1px solid $color-dashboard-inner-border;
				width: $dashboard-toggle-button-width;

				font-size: $dashboard-course-name-font-size;
				
				flex: 0 0 auto;

				position: relative;

				@include normal-transition( 'background, color' );

				background: $color-dashboard-toggle-disabled;

				#{ $section_class }--has-quizzes & {
					background: $color-dashboard-toggle-bg;
					cursor: pointer;
				}

				#{ $section_class }--has-quizzes &:before {
					@include icons-font;
					content: $icon-angle-down;
					font-size: $dashboard-toggle-button-icon-size;

					color: $color-dashboard-toggle-font-color;

					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
				}

				#{ $section_class }--expanded &,
				#{ $section_class }--expanding & {
					background: $color-dashboard-toggle-expanded-bg;
					border-right: $color-dashboard-toggle-expanded-bg;

					&:before {
						content: $icon-angle-up;
						color: $color-dashboard-toggle-expanded-font-color;
					}
				}

				/* Mobile */
				#{ $class }[max-width~="#{ $dashboard-small-breakpoint }"] & {
					width: $dashboard-toggle-button-width-mobile;
				}
			}

			#{ $section_class }__details {
				flex: 1 1 auto;
				display: flex;
				min-width: 0;

				align-items: center;

				#{ $section_class }__left {
					width: 100%;
					flex: 1 1 auto;
					min-width: 0;

					padding-left: $dashboard-space-between-elements;
					padding-right: $dashboard-space-between-elements;

					#{ $section_class }__name {
						width: 100%;
						display: block;

						position: relative;

						padding-top: $dashboard-rows-vertical-padding;
						padding-bottom: $dashboard-rows-vertical-padding;

						a {
							width: 100%;
							display: block;

							position: relative;

							/*
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							*/
							
							color: inherit;
							text-decoration: none;

							outline: none;

							@include normal-transition( 'color' );

							&:hover {
								color: $color-url-hover;
							}

							/* Tablet and mobile */
							#{ $class }[max-width~="#{ $dashboard-medium-breakpoint }"] & {
								white-space: normal;
								overflow: visible;
								text-overflow: clip;
							}
						}
					}
				}

				#{ $section_class }__right {
					flex: 0 0 auto;
					display: flex;
					align-items: center;

					padding-right: $dashboard-space-between-elements;

					#{ $section_class }__status {

						#{ $section_class }__status-circle {
							width: $dashboard-status-circle-width;
							height: $dashboard-status-circle-width;

							position: relative;

							border-radius: 50%;
							border: 1px solid $color-dashboard-status-circle-border;

							&:before {
								@include icons-font;
								content: $icon-check;
								font-size: $dashboard-status-circle-font-size;

								color: $color-dashboard-status-circle-font-color;

								position: absolute;
								top: calc( 50% + 1px );
								left: 50%;
								transform: translate(-50%,-50%);
							}

							#{ $section_class }--completed & {
								background: $color-dashboard-status-circle-completed-bg;
								border-color: $color-dashboard-status-circle-completed-bg;

								&:before {
									color: $color-dashboard-status-circle-completed-font-color;
								}
							}
						}
					}
				}
			}
		}

		&__content {
			display: none;
			padding-left: $dashboard-toggle-button-width - 1px;

			#{ $section_class }--expanded & {
				display: block;
			}

			/* Mobile */
			#{ $class }[max-width~="#{ $dashboard-small-breakpoint }"] & {
				padding-left: $dashboard-toggle-button-width-mobile - 1px;
			}
		}

		&__quizzes { 
			border-left: 1px solid $color-dashboard-border;

			& #{ $class }-quizzes__list {
				display: block;
			}
		}
	}

	&-quizzes {
		$container_class: &;

		&__header {
			display: flex;

			background: $color-section-header-background;
			color: $color-section-header-font-color;

			border-top: 1px solid $color-dashboard-inner-border;

			&-toggle-btn {
				border-right: 1px solid $color-dashboard-inner-border;
				width: $dashboard-toggle-button-width;

				font-size: $dashboard-course-name-font-size;
				cursor: pointer;

				flex: 0 0 auto;

				position: relative;

				@include normal-transition( 'background, color' );

				&:before {
					@include icons-font;
					content: $icon-angle-down;
					font-size: $dashboard-toggle-button-icon-size;

					color: $color-dashboard-toggle-font-color;

					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
				}

				#{ $container_class }--expanded &,
				#{ $container_class }--expanding & {
					background: $color-dashboard-quiz-toggle-expanded-bg;

					&:before {
						content: $icon-angle-up;
						color: $color-dashboard-quiz-toggle-expanded-font-color;
					}
				}

				/* Mobile */
				#{ $class }[max-width~="#{ $dashboard-small-breakpoint }"] & {
					width: $dashboard-toggle-button-width-mobile;
				}
			}

			&-title {
				flex: 1 1 auto;

				padding-left: $dashboard-space-between-elements;
				padding-right: $dashboard-space-between-elements;
				padding-top: $dashboard-rows-vertical-padding;
				padding-bottom: $dashboard-rows-vertical-padding;

				font-size: $dashboard-section-header-font-size;
			}
		}

		&__list {
			display: none;

			#{ $container_class }--expanded &,
			#{ $container_class }--expanding & {
				display: block;
			}
		}	
	}

		&-quiz {
			$section_class: &;

			&__row {
				display: flex;

				border-top: 1px solid $color-dashboard-inner-border;

				/* Add class if it's the quiz of a lesson */
				#{ $class }-lesson__quizzes & {
					background: $color-dashboard-lesson-content;
				}

				#{ $section_class }__details {
					flex: 1 1 auto;
					display: flex;
					min-width: 0;
					align-items: flex-start;

					#{ $section_class }__left {
						width: 100%;
						flex: 1 1 auto;
						min-width: 0;

						padding-top: $dashboard-rows-vertical-padding;
						padding-bottom: $dashboard-rows-vertical-padding;

						padding-left: $dashboard-space-between-elements;
						padding-right: $dashboard-space-between-elements;

						#{ $section_class }__name {
							width: 100%;
							display: block;

							position: relative;

							a {
								width: 100%;
								display: block;

								position: relative;

								/*
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								*/
								
								color: inherit;
								text-decoration: none;

								outline: none;

								@include normal-transition( 'color' );

								&:hover {
									color: $color-url-hover;
								}

								/* Tablet and mobile */
								#{ $class }[max-width~="#{ $dashboard-medium-breakpoint }"] & {
									white-space: normal;
									overflow: visible;
									text-overflow: clip;
								}
							}
						}

						#{ $section_class }__date {
							font-size: $dashboard-quiz-date-font-size;
							color: $color-dashboard-quiz-date;

							margin-top: 2px;
						}
					}

					#{ $section_class }__right {
						flex: 0 0 auto;
						display: flex;
						align-items: center;

						padding-top: $dashboard-rows-vertical-padding;
						padding-bottom: $dashboard-rows-vertical-padding;

						padding-left: $dashboard-space-between-elements;
						padding-right: $dashboard-space-between-elements;

						#{ $section_class }__action {
							padding-right: $dashboard-space-between-elements;

							/* Tablet and mobile */
							#{ $class }[max-width~="#{ $dashboard-medium-breakpoint }"] & {
								order: 2;

								width: 100%;

								padding-top: $dashboard-rows-vertical-padding * 2;
							}
						}

						#{ $section_class }__score {
							&-label {
								font-size: $dashboard-quiz-score-font-size;
								color: $color-dashboard-quiz-score-passed-font-color;

								border: 1px solid $color-dashboard-quiz-score-passed-bg;
								background: $color-dashboard-quiz-score-passed-bg;
								border-radius: $dashboard-border-radius;
								padding: $dashboard-quiz-score-padding;

								line-height: 1;
								text-decoration: none;
								cursor: pointer;

								display: inline-block;

								#{ $section_class }--failed & {
									color: $color-dashboard-quiz-score-failed-font-color;

									border-color: $color-dashboard-quiz-score-failed-bg;
									background: $color-dashboard-quiz-score-failed-bg;
								}
							}

							/* Tablet and mobile */
							#{ $class }[max-width~="#{ $dashboard-medium-breakpoint }"] & {
								order: 1;
							}
						}

						/* Tablet and mobile */
						#{ $class }[max-width~="#{ $dashboard-medium-breakpoint }"] & {
							width: 100%;
							
							flex-wrap: wrap;

							padding-top: 0;
						}
					}

					/* Tablet and mobile */
					#{ $class }[max-width~="#{ $dashboard-medium-breakpoint }"] & {
						flex-wrap: wrap;
					}
				}
			}
		}
}