.ultp-lazy-course-navigation {
	&-loading {
		font-family: $font_family;
		font-size: $lazy-course-navigation-global-font-size;
		color: $color-lazy-course-navigation-font-color;

		text-align: center;
		line-height: 1.4;

		box-sizing: border-box;

		* {
			box-sizing: border-box;
		}

		&__icon {
			position: relative;

			height: $lazy-course-navigation-loading-icon-size;

			&:before {
				position: absolute;
				top: 50%;
				left: 50%;

				@include icons-font;
				content: $icon-spinner-third;
				font-size: $lazy-course-navigation-loading-icon-size;

				@include animation-rotate( 'ultp-lazy-course-content-loading-icon', 1.5s, 0deg, 365deg, -50%, -50% );
			}
		}

		&__text {
			margin-top: $lazy-course-navigation-space-between-loading-elements;
		}
	}

	&__heading {
		font-family: $font_family;
		color: $color-lazy-course-navigation-font-color;

		font-size: $lazy-course-navigation-heading-font-size;
		font-weight: 700;

		line-height: 1.4;

		margin-bottom: $lazy-course-navigation-space-between-heading-and-content;
	}

	&__content {}
}